import React from 'react';
import './Disclaimer.css'

const Disclaimer = () => {
  return (
    <div className="disclaimer-container">
      <h2>Disclaimer</h2>
<p class="tg-last-updated-date">Last updated: Jan 5, 2024 2:29 PM</p>
<p>Please read this Disclaimer carefully before using the ModernSeniorGuide website at ModernSeniorGuide.com (the &ldquo;Website&rdquo;). This Website is an internet property operated by owned and operated by ModernSeniorGuide (&ldquo;ModernSeniorGuide&rdquo;, "us," "we," "our") as this Disclaimer contains important information regarding limitations of our liability. Your access to and use of the Website is conditional upon your acceptance of and compliance with this Disclaimer. This Disclaimer applies to everyone, including, but not limited to: visitors, users, and others, who wish to access or use the Website.</p>
<p>By accessing or using the Website, you agree to be bound by this Disclaimer. If you disagree with any part of this Disclaimer, then you do not have our permission to access or use the Website.</p>
<h2 class="">Advertising Disclaimer</h2>
<p>This Website contains advertisements for information, products, and services that are provided by third parties. We make no representations, or warranties about the suitability of information, products or services contained on these advertisements for any purpose. Our inclusion of such information, products, and services does not constitute their recommendation, or endorsement. When you click on an advertisement, you will be taken to a third-party website that is not controlled or managed by us. Therefore, you should read the Privacy Policy and Terms and Conditions of such websites. We do not control such websites nor the practices of such websites.</p>
<h2 class="">Health Products Disclaimer</h2>
<p>The Food and Drug Administration does not evaluate the products sold on this Website. These products are not intended to diagnose, treat, prevent, or cure any medical conditions. Individual results may vary. Always consult with a medical health professional before using any product on this Website.</p>
<h2 class="">Affiliate Disclaimer</h2>
<p>We participate in the following affiliate programs: including ADT, AARP, Endurance Auto, Nerdwallet, Clickbank, ShareASale, Awin, and Panicle. This means that we earn fees by linking to their products or services on our Website. The fact that we participate in these affiliate programs means that we are compensated for the inclusion of links that are clicked on and our reviews.</p>
<h2 class="">Health and Fitness Advice</h2>
<p>This Website offers health and fitness advice. This advice is designed for educational purposes only and is not intended to replace the advice, treatment, or diagnosis of a healthcare professional.</p>
<p>You should always consult with a healthcare professional before starting any fitness program, diet, or any other change in your healthcare routine.</p>
<p>If you experience dizziness, faintness, shortness of breath or pain while exercising, stop immediately, and consult with a healthcare professional.</p>
<p>ModernSeniorGuide is not a licensed medical provider.</p>
<p>You agree that you assume all responsibility when choosing to act on any of the health or fitness advice contained on this Website.</p>
<h2 class="">No Legal Advice</h2>
<p>Please be advised that the content provided on ModernSeniorGuide is for informational purposes only and does not constitute financial or other professional advice. We do not offer financial planning, investment, legal, tax, or any other professional services. Any information contained on this Website is not legal advice and should not be treated as such. You should always contact an attorney for help with your specific legal needs and issues. Our content is intended to provide general information and should not be considered as a substitute for professional advice. Always consult with a qualified professional for specific advice tailored to your financial situation and needs. ModernSeniorGuide is not responsible for any actions taken based on the information provided on our platform.</p>
<h2 class="">Changes</h2>
<p>We reserve the right to amend this policy at any time without any prior notice to you.</p>
<h2 class="">Questions</h2>
<p>If you have any questions about this Disclaimer, please contact us at info@ModernSeniorGuide.com.</p>
    </div>
  );
};

export default Disclaimer;
