import React from 'react';
import './TermsOfUse.css'

const TermsOfUse = () => {
    return (
      <div className="terms-of-use-container">
    <p class="p1"><span class="s1"><strong>Terms of Service</strong></span></p>
<p class="p2"><span class="s1">Last updated: Jan 5, 2024 2:32 PM</span></p>
<p class="p2"><span class="s1">Please read this Terms of Service ("Terms," "Terms of Service") carefully before using the ModernSeniorGuide website at ModernSeniorGuide.com (the &ldquo;Website&rdquo;). This Website is an internet property operated by owned and operated by ModernSeniorGuide (&ldquo;ModernSeniorGuide&rdquo;, "us," "we," "our") as this Terms of Service contains important information regarding limitations of our liability. Your access to and use of this Website is conditional upon your acceptance of and compliance with these Terms. These Terms apply to everyone, including but not limited to visitors, users and others, who wish to access and use the Website.</span></p>
<p class="p2"><span class="s1">By accessing or using the Website, you agree to be bound by these Terms. If you disagree with any part of the Terms, then you do not have our permission to access or use the Website.</span></p>
<p class="p1"><span class="s1"><strong>Prohibited uses</strong></span></p>
<p class="p2"><span class="s1">You agree that you will use this Website in accordance with all applicable laws, rules, regulations and these Terms at all times. The following is a non-exhaustive list of prohibited uses of this Website. You agree that you will not perform any of the following prohibited uses:</span></p>
<ol class="ol1">
<li class="li3"><span class="s3">Impersonating or attempting to impersonate ModernSeniorGuide or its employees, representatives, subsidiaries or divisions;</span></li>
<li class="li3"><span class="s3">Misrepresenting your identity or affiliation with any person or entity;</span></li>
<li class="li3"><span class="s3">Sending or attempting to send any advertising or promotional material, including but not limited to spam, junk mail, chain mail or any similar material;</span></li>
<li class="li3"><span class="s3">Engaging in any conduct that restricts or inhibits any person&rsquo;s use or enjoyment of the Website, or which, as determined in our sole discretion, may harm us or the users of this Website or expose us or other users to liability;</span></li>
<li class="li3"><span class="s3">Using the Website in any manner that could disable, overburden, damage or impair the Website or interfere with another party&rsquo;s use of the Website;</span></li>
<li class="li3"><span class="s3">Using any robot, spider or other similar automatic technology, process or means to access or use the Website for any purpose, including monitoring or copying any of the material on this Website;</span></li>
<li class="li3"><span class="s3">Using any manual process or means to monitor or copy any of the material on this Website or for any other unauthorized purpose;</span></li>
<li class="li3"><span class="s3">Using any device, software, means or routine that interferes with the proper working of the Website, including but not limited to viruses, trojan horses, worms, logic bombs or other such materials;</span></li>
<li class="li3"><span class="s3">Attempting to gain unauthorized access to, interfering with, damaging or disrupting any parts of the Website, the server(s) on which the Website is stored, or any server, computer or database connected to the Website;</span></li>
<li class="li3"><span class="s3">Attempting to attack or attacking the Website via a denial-of-service attack or a distributed denial-of-service attack;</span></li>
<li class="li3"><span class="s3">Otherwise attempting to interfere with the proper working of the Website;</span></li>
<li class="li3"><span class="s3">Using the Website in any way that violates any applicable federal, state or local laws, rules or regulations.</span></li>
</ol>
<p class="p1"><span class="s1"><strong>NO WARRANTY ON WEBSITE</strong></span></p>
<p class="p2"><span class="s1">THIS WEBSITE IS PROVIDED &ldquo;AS IS,&rdquo; NO WARRANTY, EXPRESS OR IMPLIED (INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, OF SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE) SHALL APPLY TO THIS WEBSITE, WHETHER ARISING BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE OR OTHERWISE.</span></p>
<p class="p1"><span class="s1"><strong>Availability, errors and inaccuracies</strong></span></p>
<p class="p2"><span class="s1">We assume no liability for the availability, errors or inaccuracies of the information, products or services provided on this Website. We may experience delays in updating information on this Website and in our advertising on other websites. The information, products and services found on the Website may contain errors or inaccuracies or may not be complete or current. Products or services may be incorrectly priced or unavailable. We expressly reserve the right to correct any pricing errors on our Website. The inclusion or offering of any product or service on this Website does not constitute an endorsement or recommendation of such product or service by us.</span></p>
<p class="p1"><span class="s1"><strong>DAMAGES AND LIMITATION OF LIABILITY</strong></span></p>
<p class="p2"><span class="s1">IN NO EVENT SHALL MODERNSENIORGUIDE BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF, RELATING TO OR IN ANY WAY CONNECTED WITH YOUR ACCESS TO, DISPLAY OF OR USE OF THIS WEBSITE OR WITH THE DELAY OR INABILITY TO ACCESS, DISPLAY OR USE THIS WEBSITE, INCLUDING BUT NOT LIMITED TO YOUR RELIANCE UPON OPINIONS OR INFORMATION APPEARING ON THIS WEBSITE; ANY COMPUTER VIRUSES, INFORMATION, SOFTWARE, LINKED WEBSITES OPERATED BY THIRD PARTIES, PRODUCTS OR SERVICES OBTAINED THROUGH THIS WEBSITE, WHETHER BASED ON A THEORY OF NEGLIGENCE, CONTRACT, TORT, STRICT LIABILITY, CONSUMER PROTECTION STATUTES OR OTHERWISE, EVEN IF MODERNSENIORGUIDE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</span></p>
<p class="p1"><span class="s1"><strong>Links to third party websites</strong></span></p>
<p class="p2"><span class="s1">This Website may contain hyperlinks to websites operated by third parties and not by us. We provide such hyperlinks for your reference only. We do not control such websites and are not responsible for their contents or the privacy or other practices of such websites. Further, it is your responsibility to take precautions to ensure that whatever links you click on or software that you download, whether from this Website or other websites or applications, is free of such items as viruses, worms, trojan horses, defects and other items of a destructive nature. Our inclusion of hyperlinks to such websites does not imply any endorsement of the material on such websites or any association with their operators.</span></p>
<p class="p1"><span class="s1"><strong>Intellectual property and DMCA notice and procedure for intellectual property infringement claims</strong></span></p>
<p class="p2"><span class="s1">All contents of this Website are &copy;2024 ModernSeniorGuide or third parties. All rights reserved. Unless specified otherwise, this Website and all content and other materials on this Website including but not limited to all logos, designs, text, graphics, pictures, information, data, software, sound files and arrangement thereof (collectively, &ldquo;Content&rdquo;) are the proprietary property of ModernSeniorGuide and are either registered trademarks, trademarks or otherwise protected intellectual property of ModernSeniorGuide or third parties in the United States and/or other countries.</span></p>
<p class="p2"><span class="s1">If you are aware of a potential infringement of our intellectual property, please contact ModernSeniorGuide.com at info@ModernSeniorGuide.com.</span></p>
<p class="p2"><span class="s1">We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Website infringes on the copyright, trademark or other intellectual property rights of any person or entity.</span></p>
<p class="p2"><span class="s1">If you believe in good faith that the Content infringes on your intellectual property rights, you or your agent may send us a written notice of such infringement titled &ldquo;Infringement of Intellectual Property Rights - DMCA.&rdquo; Your notice to us must include the following information:</span></p>
<ol class="ol1">
<li class="li3"><span class="s3">An electronic or physical signature of the person authorized to act on behalf of the owner of the intellectual property right&rsquo;s interest;</span></li>
<li class="li3"><span class="s3">A description of the work that you claim has been infringed, including the URL (i.e., web page address) of the location where the work exists or a copy of the work;</span></li>
<li class="li3"><span class="s3">Your name, email, address and telephone number; and</span></li>
<li class="li3"><span class="s3">A statement by you that you have a good faith belief that the disputed use is not authorized by the owner of the work, its agent or the law.</span></li>
</ol>
<p class="p2"><span class="s1">Please note that we will not process your complaint if it is not properly filled out or is incomplete. You may be held accountable for damages, including but not limited to costs and attorneys&rsquo; fees for any misrepresentation or bad faith claims regarding the infringement of your intellectual property rights by the Content on this Website.</span></p>
<p class="p2"><span class="s1">You may submit your claim to us by contacting us at:</span></p>
<p class="p4"><span class="s3">ModernSeniorGuide</span><span class="s1"><br /> </span><span class="s3">ModernSeniorGuide.com</span><span class="s1"><br /> </span><span class="s3">info@ModernSeniorGuide.com</span><span class="s1"><br /> </span><span class="s3">1-877-920-5565</span><span class="s1"><br /> </span><span class="s3">58-84 Norfolk street suite 23 Newark NJ 07103</span><span class="s1"><br /> </span><span class="s3">UNITED STATES</span></p>
<p class="p1"><span class="s1"><strong>Governing law, severability, dispute resolution and venue</strong></span></p>
<p class="p2"><span class="s1">These Terms shall be governed and construed in accordance with the laws of the state of New Jersey, United States, without regard to its conflict of laws provisions. THESE TERMS SHALL NOT BE GOVERNED BY THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE SALE OF INTERNATIONAL GOODS, THE UNIFORM COMMERCIAL CODE, NOR INCOTERMS.</span></p>
<p class="p2"><span class="s1">Our failure to enforce any right or provision of these Terms will not be considered a waiver of that right or provision. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between you and us regarding our Website, and supersede and replace any prior agreements we might have had with you regarding the Website.</span></p>
<p class="p2"><span class="s1">Any controversy or claim arising out of or relating to these Terms including but not limited to the interpretation or breach thereof shall be resolved in a court of competent jurisdiction in Essex County, New Jersey.</span></p>
<p class="p2"><span class="s1">YOU AND MODERNSENIORGUIDE AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION.</span></p>
<p class="p1"><span class="s1"><strong>Changes to Terms of Service</strong></span></p>
<p class="p2"><span class="s1">We reserve the right to make changes to these Terms of Service at any time. We will notify you immediately of making any changes to these Terms of Service via by posting the updated terms of service to this website.</span></p>
<p class="p1"><span class="s1"><strong>Questions</strong></span></p>
<p class="p2"><span class="s1">If you have any questions about our Terms of Service, please contact us at info@ModernSeniorGuide.com.</span></p>
<p class="p5"><span class="s3"><br /> </span></p>
    </div>
  );
};

export default TermsOfUse;
