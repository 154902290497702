// PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
    <p><strong>Privacy Policy</strong></p>
<p>Last updated: Jan 8, 2024 2:49 PM</p>
<p><br /></p>
<p>We value your privacy very highly. Please read this Privacy Policy carefully before using the ModernSeniorGuide website at ModernSeniorGuide.com (the &ldquo;Website&rdquo;). This Website is an internet property operated by owned and operated by ModernSeniorGuide (&ldquo;ModernSeniorGuide&rdquo;, &quot;us,&quot; &quot;we,&quot; &quot;our&quot;) as this Privacy Policy contains important information regarding your privacy and how we may use the information we collect about you.</p>
<p><br /></p>
<p>Your access to and use of the Website is conditional upon your acceptance of and compliance with this Privacy Policy. This Privacy Policy applies to everyone, including, but not limited to: visitors, users, and others, who wish to access or use the Website.</p>
<p><br /></p>
<p>By accessing or using the Website, you agree to be bound by this Privacy Policy. If you disagree with any part of the Privacy Policy, then you do not have our permission to access or use the Website.</p>
<p><br /></p>
<p><strong>What Information We Collect, Where We Get This Information, and How We Use It</strong></p>
<p><strong>Types of Information Collected:</strong></p>
<ul>
    <li><strong>Identifying Information:</strong> Name, Postal/Shipping address, Billing address, Phone number, IP address, Email address, Device identifier, Online identifier, Date of Birth</li>
    <li><strong>Commercial Information:</strong> Records of personal property, Products or services purchased, obtained, or considered, Purchasing or consuming histories or tendencies</li>
    <li><strong>Internet or Other Electronic Activity:</strong> Browsing history, Search history, Information regarding your interaction with our website or application, Information regarding your interactions with advertisements</li>
    <li><strong>Sensitive Personal Information:</strong> Voice recordings</li>
</ul>
<p><strong>Sources of Information:</strong></p>
<ul>
    <li>Information you provide directly to us through the website.</li>
    <li>Information automatically collected through your interactions with our website or advertisements.</li>
    <li>Information from third-party partners or service providers.</li>
</ul>
<p><strong>How We Use Your Information:</strong></p>
<ul>
    <li>To provide and maintain our services.</li>
    <li>To manage and improve user experience.</li>
    <li>To communicate with you, including for marketing purposes.</li>
    <li>To process transactions and manage your account.</li>
    <li>To analyze website usage and interactions.</li>
    <li>To prevent fraud and enhance security.</li>
    <li>To comply with legal obligations.</li>
    <li><br /></li>
    <li>Please note that if you call us, we may also record and store your call with us and may use such recording for training and customer service purposes.</li>
    <li>Note that we do not use your personal information for the purpose of profiling. Please see the &quot;Your Rights&quot; section below for more information about exercising your privacy rights related to profiling.</li>
</ul>
<p><br /></p>
<p><strong>With Whom We Share Your Personal Information</strong></p>
<p>In the last 12 months, we have disclosed and shared the following types of personal information with third parties for various reasons:</p>
<p><strong>Types of Personal Information:</strong></p>
<ul>
    <li>Identifying information: Name, Postal/Shipping address, Billing address, Phone number, IP address, Email address, Device identifier, Online identifier, Date of Birth</li>
    <li>Commercial information: Records of personal property, Products or services purchased, obtained or considered, Purchasing or consuming histories or tendencies</li>
    <li>Internet or other electronic activity: Browsing history, Search history, Information regarding your interaction with our website or application, Information regarding your interactions with advertisements</li>
    <li>Sensitive personal information: Voice recordings</li>
</ul>
<p><strong>Categories of Third Parties:</strong></p>
<ul>
    <li>Advertising networks</li>
    <li>Content management systems</li>
    <li>Customer management systems</li>
    <li>Data analytics providers</li>
    <li>Data brokers</li>
    <li>Data linking platforms</li>
    <li>Email marketing vendors</li>
    <li>Email service providers</li>
    <li>Financial transactions processors</li>
    <li>Fraud prevention services vendors</li>
    <li>Government or law enforcement entities</li>
    <li>Hosting providers</li>
    <li>Internet service providers</li>
    <li>Operating systems and platforms</li>
    <li>Social networks</li>
    <li>Parties that need to operate the website</li>
</ul>
<p><strong>Reasons for Sharing and Disclosing:</strong></p>
<ul>
    <li>To market to you via email</li>
    <li>To manage our interactions with you</li>
    <li>To prevent or investigate fraud</li>
    <li>To process transactions</li>
    <li>To sell your data</li>
    <li>To analyze your interactions with us or our website</li>
    <li>To prevent or investigate criminal activity</li>
    <li>To enforce our Terms of Service</li>
    <li>To provide you with advertisements</li>
    <li>To record voice calls</li>
</ul>
<p><br /></p>
<p><strong>Sale of your information</strong></p>
<p>We do not sell your personal information. Please see the &quot;Your Rights&quot; section below for more information about exercising your privacy rights regarding sales of your personal information.</p>
<p><strong>Cookies</strong></p>
<p>A cookie is a small piece of data sent from a website and stored on your device by your browser. This Website collects cookies. Please visit our Cookie Policy to learn more about what cookies we collect, why we collect them, and how to change your cookie settings.</p>
<p><strong>Children&apos;s privacy</strong></p>
<p>This Website is intended for use by a general audience and does not offer services to children. Should a child whom we know to be under 18 send personal information to us, we will use that information only to respond to that child to inform him or her that they cannot use this Website. We do not sell the personal information of minors under the age of 16.</p>
<p><strong>Analytics programs</strong></p>
<p>This Website uses Google Analytics to collect information about you and your behaviors. If you would like to opt out of Google Analytics, please visit <a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a>.</p>
<p><br /></p>
<p><strong>Information Retention</strong></p>
<p>We retain personal information, including Identifying information, Commercial information, Internet or other electronic activity, and Sensitive personal information, until it is no longer needed under compliance regulation.</p>
<p><br /></p>
<p><strong>Your Rights</strong></p>
<p>Depending on your residence, you may have the following rights regarding your personal information:</p>
<ul>
    <li><strong>Opt-Out of Targeted Advertising:</strong> (VA, CO, UT)</li>
    <li><strong>Access Personal Information:</strong> (CA, VA, CO, UT)</li>
    <li><strong>Know Information Collected:</strong> (CA)</li>
    <li><strong>Know Sale/Disclosure Details:</strong> (CA)</li>
    <li><strong>Opt-Out of Sale of Personal Information:</strong> (CA, NV, VA, CO, UT)</li>
    <li><strong>Opt-Out of Sharing Personal Information:</strong> (CA)</li>
    <li><strong>Opt-Out of Profiling:</strong> (VA, CO)</li>
    <li><strong>Request Deletion of Personal Information:</strong> (CA, VA, CO, UT)</li>
    <li><strong>Non-Discrimination:</strong> (CA, VA, CO, UT)</li>
    <li><strong>Equal Service and Price:</strong> (CA, VA, CO, UT)</li>
    <li><strong>Transmit Information to Another Provider:</strong> (CA)</li>
    <li><strong>Request Amendments:</strong> (CA, VA, CO)</li>
    <li><strong>Receive Information in Portable Format:</strong> (CA, VA, CO, UT)</li>
    <li><strong>Limit Use/Disclosure of Sensitive Information:</strong> (CA)</li>
    <li><strong>Opt-Out of Processing Sensitive Information:</strong> (UT)</li>
</ul>
<p><br /></p>
<p><strong>Exercising your rights</strong></p>
<p>You may exercise the rights specified above by submitting a consumer request to:</p>
<p>ModernSeniorGuide<br />President<br />info@ModernSeniorGuide.com<br />1-877-920-5565<br />58-84 Norfolk street suite 23 Newark NJ 07103<br />United States<br /><a href="https://ModernSeniorGuide.com/do-not-sell">Do Not Share My Personal Information</a><br /><a href="https://ModernSeniorGuide.com/do-not-sell">Limit The Use Of My Sensitive Personal Information</a></p>
<p>If you use our cookie consent banner to exercise your privacy rights, the opt out signal will be applied to your browser. If you opt-out by contacting us as stated above, your preference will be applied to your account and our databases, including offline sales.</p>
<p>We will need to verify your identity prior to effectuating your request. To verify your identity, you will need to provide us with the following information with your request:</p>
<p><br /></p>
<ul>
    <li>Name;</li>
    <li>Postal / Shipping address;</li>
    <li>Phone number;</li>
    <li>Email address.</li>
</ul>
<p>Please note that we may be unable to process your request if you do not provide us with the above information.</p>
<p>You may also designate an authorized agent to exercise your rights on your behalf. You may designate an agent via any of the ways used to submit requests on your behalf. We will request the agent to verify that he or she has the authority to submit requests on your behalf. We will do so by asking the agent to submit the following information:</p>
<p><br /></p>
<ul>
    <li>Valid power of attorney.</li>
</ul>
<p>Please note that we may not be able to process your request if your designated agent and/or you do not provide us with the above information.</p>
<p><br /></p>
<p>We will respond to most consumer requests within 30 to 45 days of receipt, depending upon where you reside. However, some requests may take longer. We will notify you in writing if we need more time to respond. We have the ability to deny your request(s) if certain exceptions in the law apply. If we do deny your request, we will provide you with the reasons for such denial.</p>
<p>You have the right to appeal a refusal to take action on a rights request. You may file an appeal to us at the contact information provided above. We will respond to most appeal requests within 45 days of receipt. However, some requests may take longer. We will notify you in writing if we need more time to respond (up to 90 days total). In our response to your appeal, we will inform you of any actions taken or not taken and the reason(s) as to why. Normally, we do not charge a fee to process or respond to consumer requests. However, we may charge a fee for a second or subsequent request within a 12-month period.</p>
<p><strong>Third-party websites</strong></p>
<p>This Website may contain hyperlinks to websites operated by parties other than us. We provide such hyperlinks for your reference only. We do not control such websites and are not responsible for their contents or the privacy or other practices of such websites. It is up to you to read and fully understand their Privacy Policies. Our inclusion of hyperlinks to such websites does not imply any endorsement of the material on such websites or any association with their operators.</p>
<p><strong>Do Not Track</strong></p>
<p>Do Not Track is a preference you can set on your browser to inform websites that you do not want to be tracked. We do not support Do Not Track (&quot;DNT&quot;). You can either enable or disable Do Not Track by visiting the Preferences or Settings page of your browser.</p>
<p><strong>Changes to Privacy Policy</strong></p>
<p>We reserve the right to amend this Privacy Policy at any time. We will notify you of any changes to this Privacy Policy by posting the updated Privacy Policy to this website or application.</p>
<p><strong>Questions</strong></p>
<p>If you have any questions about this Privacy Policy, please contact us at info@ModernSeniorGuide.com.</p>
<p><br /></p>
    </div>
  );
};

export default PrivacyPolicy;
